import { LOCAL_STORAGE_SERVICE } from "../../../core/services/storage-service";

// const getDecodedAuthorities = (authorities) => {
//   const authoritiesToDecode = authorities;
//   if (authoritiesToDecode) {
//     for (let i = 0; i < authoritiesToDecode.length; i++) {
//       authoritiesToDecode[i] = HELPER.encryptionService.decode(
//         authoritiesToDecode[i]
//       );
//     }
//   }
//   return authoritiesToDecode;
// };

export function persistLoginDataAndRedirect(
  data,
  mainDispatch,
  navigate,
  isChangePasswordAutoLogin = false
) {
  // const authorities = CONFIG.IS_ENCRYPTED_AUTHORITIES
  //   ? getDecodedAuthorities(data?.cosmosResponse?.authorities)
  //   : data?.cosmosResponse?.authorities;

  let loginData = {
    username: data?.username,
    email: data?.email,
    access_token: data?.cosmosResponse?.accessToken,
    // It's better to store the encrypted authorities
    authorities: data?.cosmosResponse?.authorities,
    expires_in: data?.cosmosResponse?.expiresIn,
    firstName: data?.firstName,
    lastName: data?.lastName,
    roles: data?.cosmosResponse?.roles,
    isAuthenticated: true,
    name: data?.firstname + " " + data?.lastname,
    selectedSideNavIndex: 0,
    merchantId: data?.merchantId,
    merchantName: data?.branch,
    partyUserId: data?.partyTypeIdentifier,
    partyType: data?.partyType,
  };

  LOCAL_STORAGE_SERVICE.SAVE_USER_CREDENTIAL(loginData);
  mainDispatch({
    type: "PERSIST_LOGIN_DATA",
    loginData: loginData,
  });
  // Changing deafult password requires temporarily storing the users login data which might still have
  // the "resetPassword" property as true.
  if (data.resetPassword && !isChangePasswordAutoLogin) {
    navigate("/change-password");
  } else {
    data?.partyTypeIdentifier
      ? navigate("/third-party-transactions")
      : navigate("/transactions");
  }
}
