import React, { useCallback, useEffect, useState } from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { DEFAULT_PAGE_SIZE } from "../../../core/configs/configs";
import { HELPER } from "../../../core/helper/helper";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { PageActions } from "../../../shared/components/page-actions/page-actions";
import { PageTitle } from "../../../shared/components/page-title/page-title";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { Optional } from "../../../shared/components/optional/optional";
import { useNotification } from "core/hooks/useNotification";
import { API_SERVICE } from "api/service";
import { PartyUserForm } from "./party-user-form";
import { PartyUsersFilter } from "./party-users-filter";
import { PartyUserDetails } from "./party-user-details";

export function PartyUsers() {
  const { addNotification } = useNotification();
  const [modalIndex, setModalIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const tableHeaders = [
    { label: "First Name", value: "firstName" },
    { label: "Last Name", value: "lastName" },
    { label: "Email", value: "email" },
    { label: "Party Type", value: "partyType" },
    { label: "Status", value: "active" },
    { label: "Created At", value: "createdAt" },
    { label: "Actions", value: "actions" },
  ];

  const [partyUsers, setPartyUsers] = useState([]);
  const [selectedPartyUser, setSelectedPartyUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [error, setError] = useState(null);
  const [filterParams, setFilterParams] = useState(null);

  const handleResponse = useCallback((response) => {
    setPagination(response?.result);
    setPartyUsers(response?.result?.content);
    setError(null);
    setLoading(false);
  }, []);

  const handleError = useCallback(
    (error) => {
      setLoading(false);
      setError(HELPER.PROCESS_ERROR(error));
      addNotification({
        message: HELPER.PROCESS_ERROR(error),
        type: "error",
      });
    },
    [addNotification]
  );

  const getPartyUsers = useCallback(
    async (filters) => {
      setLoading(true);
      const params = HELPER.TO_URL_STRING({
        page: pageNo,
        size: DEFAULT_PAGE_SIZE,
        ...filters,
      });

      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          `${BACK_OFFICE_API.PARTY_USERS.GET_PARTY_USERS}?${params}`
        );
        handleResponse(response);
      } catch (error) {
        handleError(error);
      }
    },
    [pageNo, handleResponse, handleError]
  );

  useEffect(() => {
    if (!filterParams) {
      getPartyUsers();
    } else {
      getPartyUsers(filterParams);
    }
  }, [getPartyUsers, filterParams]);

  function reload() {
    setFilterParams(null);
    setPageNo(0);
  }

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function closeModal(shouldReload) {
    if (!modalIndex) setPageNo(0);
    setIsModalVisible(false);
    if (shouldReload === true) {
      getPartyUsers();
    }
  }

  const handlePartyUsersFilter = (filters) => {
    setFilterParams(filters);
    setIsModalVisible(false);
  };

  const handleTableAction = (data, action) => {
    switch (action) {
      case "VIEW":
        setSelectedPartyUser(data);
        toggleModal(2);
        break;
      default:
        break;
    }
  };

  const handleFilterClick = () => {
    toggleModal(3);
  };

  function modalContent() {
    switch (modalIndex) {
      case 0:
        return (
          <PartyUserForm
            closeModal={closeModal}
            isCreate={true}
            onSuccess={getPartyUsers}
          />
        );
      case 1:
        return (
          <PartyUserForm
            closeModal={closeModal}
            isUpdate={true}
            onSuccess={getPartyUsers}
            partyUser={selectedPartyUser}
          />
        );
      case 2:
        return (
          <PartyUserDetails
            partyUser={selectedPartyUser}
            closeModal={closeModal}
            onReload={getPartyUsers}
          />
        );
      case 3:
        return (
          <PartyUsersFilter
            onFilter={handlePartyUsersFilter}
            closeModal={closeModal}
          />
        );
      default:
        break;
    }
  }

  const renderTable = () => {
    if (loading) {
      return (
        <div className="loading-container text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    } else {
      return (
        <CustomTable
          authorities={[
            { label: "VIEW", value: "switch_get_one_party_office_user" },
          ]}
          isReload={true}
          numberOfElements={pagination?.numberOfElements}
          totalPages={pagination?.totalPages}
          totalItems={pagination?.totalElements}
          currentPage={pageNo + 1}
          emptyText={"No users found!"}
          search={true}
          reload={reload}
          error={error}
          items={partyUsers}
          loading={loading}
          headers={tableHeaders}
          nextPage={() => setPageNo(pageNo + 1)}
          prevPage={() => setPageNo(pageNo - 1)}
          goToFirstPage={() => setPageNo(0)}
          goToLastPage={() => setPageNo(pagination?.totalPages - 1)}
          goToPage={(pageNo) => setPageNo(pageNo)}
          actions={["VIEW"]}
          onPerformAction={handleTableAction}
        />
      );
    }
  };

  return (
    <div className="manage-party-users">
      <>
        <CustomModal
          closeModal={closeModal}
          onHide={closeModal}
          visible={isModalVisible}
          modalContent={modalContent}
          hasOwnModalHeader={true}
        />
      </>
      <PageTitle text="Users" />
      <CustomBreadcrumb page="Users" />
      <Optional
        showIf={HELPER.HAS_AUTHORITY("switch_create_party_office_user")}
      >
        <PageActions>
          <AppButton
            icon="filter"
            text="Filter"
            type="button"
            buttonStyle="bare"
            margin={"mr-2"}
            onclick={handleFilterClick}
          />
          <AppButton
            type="button"
            buttonStyle="primary"
            text="New User"
            onclick={() => {
              toggleModal(0);
            }}
          />
        </PageActions>
      </Optional>
      <>{renderTable()}</>
    </div>
  );
}
