import "./utilities.css";
export function FormActions({ children }) {
  return (
    <div
      className="flex justify-content-between align-items-end actions"
      style={{ margin: "1rem 0 1rem" }}
    >
      {children}
    </div>
  );
}
