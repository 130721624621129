import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { FormRadioInput } from "../../../shared/components/form-component/form-radio-input";
import { API_SERVICE } from "../../../api/service";
import { Optional } from "../../../shared/components/optional/optional";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { HELPER } from "../../../core/helper/helper";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { useNotification } from "core/hooks/useNotification";
import { FormattedDetails } from "shared/components/formatted-details/formatted-details";

export function FinancialInstitutionForm(props) {
  const [toastType, setToastType] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { addNotification } = useNotification();
  const [initialValues, setInitialValues] = useState({
    name: "",
    code: "",
    active: "",
    isSettlementBank: true,
  });
  const validationSchemaObject = {
    name: Yup.string()
      .max(255, `Too long, maximum length is 255 characters`)
      .required("Required"),
    code: Yup.string()
      .max(25, `Too long, maximum length is 25 characters`)
      .required("Required"),
  };
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const FORM_VIEW_INDEX = 0;
  const FORM_DETAILS_CONFIRMATION_INDEX = 1;
  const [formValues, setFormValues] = useState(null);

  useEffect(() => {
    if (props.isUpdate) {
      const { name, code, active, settlementBank } = props.financialInstitution;
      setInitialValues({
        name,
        code,
        active,
        isSettlementBank: settlementBank === "Yes" ? true : false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isUpdate]);

  const handleResponse = (resetFormFuntion) => {
    const message = `Financial Institution successfully ${
      props.isUpdate ? "updated" : "created"
    }!`;
    addNotification({
      message,
      type: "success",
    });
    const shouldReload = true;
    props.closeModal(shouldReload);
    setLoading(false);
    if (!props.isUpdate) {
      resetFormFuntion({ name: "", code: "", active: "" });
    }
    setLoading(false);
  };

  const handleError = (error) => {
    setToastType("error");
    setMessage(HELPER.PROCESS_ERROR(error));
    setLoading(false);
  };

  const updateFinancialInstitution = async (payload) => {
    const url = `${BACK_OFFICE_API.FINANCIAL_INSITUTION.UPDATE_FINANCIAL_INSTITUTION}/${props.financialInstitution.code}`;
    try {
      await API_SERVICE.MAKE_PUT_REQUEST(url, payload);
      handleResponse();
    } catch (error) {
      handleError(error);
    }
  };

  const createFinancialInstitution = async (payload, resetFormFuntion) => {
    const url =
      BACK_OFFICE_API.FINANCIAL_INSITUTION.CREATE_FINANCIAL_INSTITUTION;
    try {
      await API_SERVICE.MAKE_POST_REQUEST(url, payload);
      handleResponse(resetFormFuntion);
    } catch (error) {
      handleError(error);
    }
  };

  const processSubmission = (
    { isSettlementBank, active, ...payload },
    resetFormFuntion
  ) => {
    let formattedPayload = {
      ...payload,
      active,
      isSettlementBank,
    };
    setLoading(true);
    if (props.isUpdate) {
      updateFinancialInstitution(formattedPayload);
    } else {
      createFinancialInstitution(formattedPayload, resetFormFuntion);
    }
  };

  const processConfirmationViewData = ({
    isSettlementBank,
    active,
    ...payload
  }) => {
    let confirmationPayload = props.isUpdate
      ? {
          ...payload,
          isSettlementBank: isSettlementBank ? "Yes" : "No",
          status: active ? "Active" : "Inactive",
        }
      : { ...payload, isSettlementBank: isSettlementBank ? "Yes" : "No" };
    setFormValues(confirmationPayload);
  };

  const submit = async (payload, resetFormFuntion) => {
    setToastType("");
    setMessage("");
    if (currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX) {
      processSubmission(payload, resetFormFuntion);
    } else {
      processConfirmationViewData(payload);
      setCurrentFormIndex(FORM_DETAILS_CONFIRMATION_INDEX);
    }
  };

  const handleClose = () => {
    if (currentFormIndex === FORM_VIEW_INDEX) {
      props.closeModal();
    } else {
      setCurrentFormIndex(FORM_VIEW_INDEX);
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchemaObject)}
        onSubmit={(values, { resetForm }) => {
          submit(values, resetForm);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <Optional showIf={currentFormIndex === FORM_VIEW_INDEX}>
              <AppFormInput
                label="Name"
                name="name"
                type="text"
                placeholder="Input financial insitution name"
                required={true}
              />
              <AppFormInput
                required={true}
                label="Code"
                name="code"
                type="text"
                placeholder="Input financial insitution code"
                // disabled={props.isUpdate}
                disabled={false}
                // showtooltip={"true"}
                // tooltiptext={CODE_TIP}
              />
              <div>
                <p
                  style={{
                    margin: 0,
                    fontSize: "0.9rem",
                    fontWeight: "bold",
                    marginBottom: "0.5rem",
                    display: "block",
                    color: "#566a7f",
                    textAlign: "left",
                  }}
                >
                  Is a settlement bank? :{" "}
                  <span style={{ color: "red" }}> * </span>
                </p>
                <div className="flex default p-mb-4">
                  <FormRadioInput
                    id="active"
                    value={true}
                    name="isSettlementBank"
                    checked={values.isSettlementBank === true}
                    handleChange={(e) =>
                      setFieldValue("isSettlementBank", true)
                    }
                    label="Yes"
                  />
                  <FormRadioInput
                    id="Inactive"
                    value={false}
                    name="isSettlementBank"
                    checked={values.isSettlementBank === false}
                    handleChange={(e) =>
                      setFieldValue("isSettlementBank", false)
                    }
                    label="No"
                  />
                </div>
              </div>
              <Optional showIf={props.isUpdate}>
                <div className="mt-3">
                  <p className="m-0 text-sm font-bold mb-2 block text-left text-default-color">
                    Status: <span style={{ color: "red" }}> * </span>
                  </p>
                  <div className="flex default p-mb-4">
                    <FormRadioInput
                      id="active"
                      value={true}
                      name="Status"
                      checked={values.active === true}
                      handleChange={(e) => setFieldValue("active", true)}
                      label="Active"
                    />
                    <FormRadioInput
                      id="Inactive"
                      value={false}
                      name="Status"
                      checked={values.active === false}
                      handleChange={(e) => setFieldValue("active", false)}
                      label="Inactive"
                    />
                  </div>
                </div>
              </Optional>
            </Optional>
            <Optional
              showIf={currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX}
            >
              <FormattedDetails details={formValues} />
            </Optional>
            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text={
                  currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX
                    ? "Back"
                    : "Cancel"
                }
                onclick={handleClose}
              />
              <AppButton
                type="submit"
                buttonStyle="primary"
                text="Submit"
                loading={loading}
              />
            </FormActions>
            {message && (
              <CustomToast
                title={toastType === "error" ? "Error" : "Success"}
                description={message}
                type={toastType}
                closeable={false}
                inApp={true}
              />
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}
