import moment from "moment";
import { LOCAL_STORAGE_SERVICE } from "../services/storage-service";
import { ProgressBar } from "../../shared/components/progress-bar/progress-bar";
import { responseCodeConfig } from "./response-code-config";
import { isValidPhoneNumber, getCountryCallingCode } from "libphonenumber-js";

const encryptionService = {
  decode,
};

function decode(encoded) {
  const encodedLength = encoded.length;

  let textArray = encoded.split("");

  for (let i = 0; i < 5; i++) {
    const firstChar = textArray[0];
    for (let j = 0; j < encodedLength - 1; j++) {
      textArray[j] = textArray[j + 1];
    }
    textArray[encodedLength - 1] = firstChar;
  }

  const text = textArray.join("").replaceAll(":", "=");
  return atob(text);
}

const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 500);
  };
};
export function getCountryCode(country) {
  const countryCode = getCountryCallingCode(country);
  return countryCode;
}

export function validatePhoneNumber(value, country) {
  if (!value) {
    return true;
  }
  if (value) {
    return isValidPhoneNumber(value, country);
  }
}

function toCurrencyFormat(e) {
  if (e) {
    e = parseFloat(e) / 100;

    if (e < 0.1) {
      return `₦${parseFloat(e)}`;
    }
    return (
      "₦" +
      parseFloat(e)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    );
  } else {
    return "₦0.00";
  }
}

function toNumberFormat(value) {
  return new Intl.NumberFormat().format(value);
}

function formatDate(date) {
  if (typeof date === "object") {
    if (date) {
      let dateLength = date?.length;
      if (dateLength && dateLength === 7) {
        // removes millisecond
        date.pop();
      }
      return `${moment(date, "YYYY MM DD HH mm ss").format(
        "MMM Do YY"
      )} | ${moment(date, "YYYY MM DD HH mm ss").format("LTS")}`;
    } else {
      return "------";
    }
  } else {
    return `${moment(date).format("MMM Do YY")} | ${moment(date).format(
      "LTS"
    )}`;
  }
}

function formatDateOnly(date) {
  if (!date) {
    return "------";
  }
  if (date && typeof date === "object") {
    let dateLength = date?.length;
    if (dateLength && dateLength === 7) {
      // removes millisecond
      date.pop();
    }
    return `${moment(date, "YYYY MM DD HH mm ss").format("MMM DD, YYYY")}`;
  } else {
    return `${moment(date).format("MMM DD, YYYY")}`;
  }
}

function formatDateInMilliseconds(date) {
  if (date) {
    return `${moment(date).format("MMM Do YY")} | ${moment(date).format(
      "LTS"
    )}`;
  } else {
    return "------";
  }
}

function setCountdown(date) {
  if (!date) {
    return `${0} Day(s) ${0} Hours ${0} minutes ${0} seconds`;
  }
  let countdownDate;
  if (typeof date === "object") {
    // Remove millisicond from end of date array to allow proper handling of date
    if (date.length > 6) {
      date.pop();
    }
    let disputeExpiryDate = [...date];
    // Subtract one from the month at position 1 because date format months start at 0
    --disputeExpiryDate[1];
    countdownDate = new Date(...disputeExpiryDate).getTime();
  } else {
    countdownDate = new Date(date).getTime();
  }
  var now = new Date().getTime();
  var distance = countdownDate - now;
  if (!distance || distance < 0) {
    return false;
  } else {
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    return `${days} Day(s) ${hours} Hours ${minutes} minutes ${seconds} seconds`;
  }
}
function getExpiryHour(date) {
  if (!date) {
    return null;
  }
  let expiryDate;
  if (typeof date === "object") {
    let disputeExpiryDate = [...date];
    // Subtract one from the month at position 1 because date format months start at 0
    --disputeExpiryDate[1];
    expiryDate = new Date(...disputeExpiryDate).getTime();
  } else {
    expiryDate = new Date(date).getTime();
  }

  var now = new Date().getTime();
  var distance = expiryDate - now;
  if (distance < 0) {
    return null;
  } else {
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    if (days < 1) {
      return hours;
    } else {
      return null;
    }
  }
}

const responseCodeColor = (responseCode) => {
  const formattedResponseCode =
    responseCode === "00" ? responseCode : responseCode[0];

  return (
    <span
      className="custom-badge custom-badge-code"
      style={{
        backgroundColor: responseCodeConfig[formattedResponseCode]?.color
          ? responseCodeConfig[formattedResponseCode].color
          : "#FFB850",
        fontWeight: "600",
        color: "#ffffff",
      }}
    >
      {responseCode}
    </span>
  );
};

function titleCase(str) {
  if (str === null || str === "" || !str) return false;
  else str = str.toString();

  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function getProcessError(error, specialCase = null) {
  const errorData = error?.data?.errors;
  const err = error?.data?.error
    ? [`${error?.data?.error}`]
    : ["Something went wrong ..."];
  const errors = errorData?.length ? errorData : err;

  let result = "";
  errors.forEach((e) => {
    result += e.message
      ? specialCase === "NAME_ENQUIRY" || specialCase === "TOAST"
        ? e.message
        : `${e.message}`
      : e;
  });
  return result;
  // }
}
function getYupErrors(errors) {
  const properties = Object.keys(errors);
  let errorString;
  let arrayOfErrors = properties.map(
    (property) =>
      `${camelToTitleCase(property)} is ${errors[property].toLowerCase()}`
  );
  errorString =
    arrayOfErrors.length > 1
      ? arrayOfErrors.join(", ")
      : arrayOfErrors.join("");

  return errorString;
}

async function parseBlobError(error) {
  const parsedError = JSON.parse(await error?.data?.text());
  const errors = parsedError?.errors
    ? parsedError?.errors
    : parsedError?.error
    ? [parsedError?.error]
    : ["Something went wrong..."];
  let errMessage = "";
  errors.forEach((error) => {
    errMessage += error?.message ? error?.message : error;
  });
  return errMessage;
}

function percentageStatus(value) {
  if (value === null) {
    return "------";
  } else {
    return <ProgressBar value={value} height="1rem" />;
  }
}

const successfulStatuses = [
  "successCount",
  "success",
  "available",
  "successful",
  "ok",
  "credit",
  "accepted",
  "resolved",
  "approved",
  "active",
  "completed",
  "created",
  "true",
  "enabled",
  "yes",
  "closed",
];
const warningStatuses = [
  "uploadTotalCount",
  "pending",
  "ready_for_file_generation",
  "in_progress",
];
const errorStatuses = [
  "declined",
  "debit",
  "failed",
  "blocked",
  "deleted",
  "rejected",
  "disabled",
];

function responseStatus(response, property) {
  if (typeof response === "boolean") {
    response = response.toString();
    if (property === "active") {
      response = response === "true" ? "Active" : "Inactive";
    }
  }

  if (typeof response === "number") {
    switch (property) {
      case "uploadTotalCount":
        return (
          <span className="custom-badge custom-badge-text custom-badge-pending">
            {response}
          </span>
        );
      case "successCount":
        return (
          <span className="custom-badge custom-badge-text custom-badge-success">
            {response}
          </span>
        );
      case "failedCount":
        return (
          <span className="custom-badge custom-badge-text custom-badge-error">
            {response}
          </span>
        );
      default:
        return;
    }
  }
  const status = response ? response?.toString()?.toLowerCase() : response;
  let statusToDisplay = titleCase(status);
  if (!response) {
    return "------";
  }
  if (successfulStatuses.includes(status)) {
    return (
      <span className="custom-badge custom-badge-text custom-badge-success">
        {statusToDisplay}
      </span>
    );
  } else if (warningStatuses.includes(status)) {
    return (
      <span className="custom-badge custom-badge-text custom-badge-pending">
        {statusToDisplay}
      </span>
    );
  } else if (errorStatuses.includes(status)) {
    return (
      <span className="custom-badge custom-badge-text custom-badge-error">
        {statusToDisplay}
      </span>
    );
  } else if (status === "") {
    return "------";
  } else {
    return (
      <span className="custom-badge custom-badge-text custom-badge-warning">
        {statusToDisplay}
      </span>
    );
  }
}

function toTitleCase(str) {
  if (str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }
  return str;
}

function toUrlString(obj) {
  let urlString = "";
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      if (obj[prop] != null && obj[prop].constructor === Array) {
        urlString = createUrlString(obj[prop], prop);
      } else {
        urlString += prop + "=" + obj[prop] + "&";
      }
    }
  }
  return urlString.substr(0, urlString.length - 1);
}

function createUrlString(arr, prop) {
  let urlString = "";
  arr.forEach((i) => {
    urlString += `${prop}=${i}&`;
  });
  return urlString;
}

function getRefineRole() {
  const roles = LOCAL_STORAGE_SERVICE.GET_ROLES();
  if (!roles) {
    return "-";
  }
  switch (roles[0]) {
    case "APTPAY_SWITCH_BACKOFFICE_ADMIN":
      return "Backoffice Admin";
    case "APTPAY_SWITCH_MERCHANT_SUPER_ADMIN":
      return "Super Agent Admin";
    case "DCIR_BACKOFFICE_OPERATOR":
      return "Backoffice Operator";
    default:
      return roles[0]?.split("_")?.join(" ")?.toLowerCase();
  }
}

// Not sure if this does the same as the decode function above so I've modified the name here
function decodeVal(encodedText) {
  let textChar = Array.from(encodedText);
  textChar = [...textChar.slice(5), ...textChar.slice(0, 5)];
  let base64Encoded = textChar.join("");
  base64Encoded = base64Encoded.replace(/:/g, "=");
  try {
    const decodedBytes = atob(base64Encoded);
    return decodedBytes;
  } catch (e) {
    throw new Error("Error while decoding token: " + e.message);
  }
}

function hasAuthority(authority) {
  if (authority === "all") {
    return true;
  }
  const authorities = LOCAL_STORAGE_SERVICE.GET_AUTHORITIES();
  if (!authorities) {
    return false;
  }
  const decodedAuthorities = authorities.map((authority) =>
    decodeVal(authority)
  );
  return decodedAuthorities.includes(authority);
}

function hasRole(rolesArray) {
  let hasRole = false;
  const roles = LOCAL_STORAGE_SERVICE.GET_ROLES();
  rolesArray.forEach((e) => {
    if (roles.includes(e)) {
      hasRole = true;
    }
  });
  return hasRole;
}

function canPerformAction(authorities, action) {
  let authority = "";
  authorities.forEach((e) => {
    if (e.label === action) {
      authority = e.value;
    }
  });

  return hasAuthority(authority);
}

function getDateDifference(startDate, endDate) {
  let startDateObj = new Date(startDate);
  let endDateObj = new Date(endDate);
  let dateDifference =
    Math.abs(startDateObj.getTime() - endDateObj.getTime()) /
    1000 /
    60 /
    60 /
    24;
  return dateDifference;
}

function removeEmptyValues(obj) {
  const propNames = Object.getOwnPropertyNames(obj);
  // @ts-ignore
  propNames.forEach((propName) => {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === "undefined" ||
      String(obj[propName]) === ""
    ) {
      delete obj[propName];
    }
  });
  return obj;
}

function changeNullValuesToEmptyStrings(obj) {
  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (obj[property] === null) {
        obj[property] = "";
      }
    }
  }
  return obj;
}

function camelToTitleCase(text) {
  const result = text.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

function getDisputeDetailsArray(dispute) {
  let arr = [];
  arr.push({
    label: "Resolution status",
    value: dispute?.resolutionStatus,
    itemCase: "resolutionStatus",
  });
  arr.push({
    label: "Dispute status",
    value: dispute?.status,
    itemCase: "status",
  });
  arr.push({ label: "Log code", value: dispute?.logCode });
  arr.push({ label: "Logged by", value: dispute?.loggedBy });
  arr.push({
    label: "Logged on",
    value: dispute?.createdOn,
    itemCase: "createdOn",
  });
  arr.push({ label: "Reason", value: dispute?.reason });
  arr.push({ label: "Resolved by", value: dispute?.resolvedBy || "___" });
  arr.push({
    label: "Transaction amount",
    value: dispute?.transactionAmount,
    itemCase: "transactionAmount",
  });
  arr.push({
    label: "Transaction response code",
    value: dispute?.transactionResponseCode,
    itemCase: "responseCode",
  });
  arr.push({
    label: "Transaction search key",
    value: dispute?.transactionSearchKey,
    action: "viewTransaction",
    transactionDetails: dispute,
  });
  arr.push({
    label: "Transaction time",
    value: dispute?.transactionTime,
    itemCase: "createdOn",
  });
  arr.push({ label: "Super Agent id", value: dispute?.merchantId });
  arr.push({ label: "Super Agent name", value: dispute?.merchantName });
  arr.push({
    label: "Customer account name",
    value: dispute?.customerAccountName,
  });
  arr.push({
    label: "Customer account no",
    value: dispute?.customerAccountNumber,
  });

  if (dispute?.refundTransaction) {
    arr.push({
      label: "Refund status",
      value: dispute?.refundTransaction?.status,
      itemCase: "status",
    });
    arr.push({
      label: "Refund amount",
      value: dispute?.refundTransaction?.amount,
      itemCase: "transactionAmount",
    });
    arr.push({
      label: "Refund destination account",
      value: dispute?.refundTransaction?.destinationAccount,
    });
    arr.push({
      label: "Refund destination account name",
      value: dispute?.refundTransaction?.destinationAccountName,
    });
    arr.push({
      label: "Refund source account",
      value: dispute?.refundTransaction?.sourceAccount,
    });
    arr.push({
      label: "Refund source account name",
      value: dispute?.refundTransaction?.sourceAccountName,
    });
    arr.push({
      label: "Refund request creation time",
      value: dispute?.refundTransaction?.createdOn,
      itemCase: "createdOn",
    });
    arr.push({
      label: "Refund last update time",
      value: dispute?.refundTransaction?.lastUpdatedTime,
      itemCase: "createdOn",
    });
    arr.push({
      label: "Refund attempts",
      value: dispute?.refundTransaction?.attempts,
    });
    arr.push({
      label: "Refund narration",
      value: dispute?.refundTransaction?.narration,
    });
  }

  return arr;
}

function toMinorAmount(amountInMajor) {
  const amount = (Number(amountInMajor) * 100).toFixed(2);
  return parseFloat(amount);
}

function toTwoDecimalPlacesMinorAmount(amountInMajor) {
  const amount = (Number(amountInMajor) * 100).toFixed(2);
  return parseFloat(amount);
}

function toMajorAmount(amountInMinor) {
  return Number(amountInMinor) / 100;
}

export const HELPER = {
  TO_CURRENCY_FORMAT: toCurrencyFormat,
  TO_MINOR_AMOUNT: toMinorAmount,
  TO_MAJOR_AMOUNT: toMajorAmount,
  TO_NUMBER_FORMAT: toNumberFormat,
  FORMAT_DATE: formatDate,
  FORMAT_DATE_ONLY: formatDateOnly,
  FORMAT_DATE_IN_MILLISECONDS: formatDateInMilliseconds,
  RESPONSE_CODE_COLOR: responseCodeColor,
  RESPONSE_STATUS: responseStatus,
  PROCESS_ERROR: getProcessError,
  PROCESS_YUP_ERRORS: getYupErrors,
  TO_URL_STRING: toUrlString,
  GET_ROLE: getRefineRole,
  HAS_AUTHORITY: hasAuthority,
  HAS_ROLE: hasRole,
  CAN_PERFORM_ACTION: canPerformAction,
  TITLE_CASE: toTitleCase,
  SET_COUNTDOWN: setCountdown,
  GET_EXPIRY_HOUR: getExpiryHour,
  PARSE_BLOB_ERROR: parseBlobError,
  PERCENTAGE_STATUS: percentageStatus,
  GET_DATE_DIFFERENCE: getDateDifference,
  TRIM_OBJECT: removeEmptyValues,
  GET_DISPUTE_DETAILS_ARRAY: getDisputeDetailsArray,
  CAMEL_TO_TITLE_CASE: camelToTitleCase,
  changeNullValuesToEmptyStrings,
  debounce,
  toTwoDecimalPlacesMinorAmount,
  validatePhoneNumber,
  getCountryCode,
  encryptionService,
};
