import React, { useCallback, useEffect, useState } from "react";
import { FormattedDetails } from "shared/components/formatted-details/formatted-details";
import { Icon } from "shared/components/icons/icon";
import { ImageView } from "shared/components/image-view/image-view";
import { Optional } from "shared/components/optional/optional";
import TransactionDisputeHistoryLog from "../transactions/dispute-history-log";
import { AppButton } from "shared/components/app-button/app-button";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { ResolveDispute } from "./resolve-dispute";
import { ReopenDispute } from "./reopen-dispute";
import { TabPanel, TabView } from "primereact/tabview";
import { BACK_OFFICE_API } from "api/backofffice/index";
import { API_SERVICE } from "api/service";
import { DECLINED, PENDING } from "shared/constants";
import { HELPER } from "core/helper/helper";
import { ErrorView } from "shared/components/error-view/error-view";
import { LOCAL_STORAGE } from "core/services/storage-service";

const DisputeDetails = (props) => {
  const [loggedInUsersUsername] = useState(
    localStorage.getItem(LOCAL_STORAGE.USER_NAME)
  );
  const [showReceiptData, setShowReceiptData] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [disputeDetails, setDisputeDetails] = useState({});
  const [disputeSettlementInformation, setDisputeSettlementInformation] =
    useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [additionalDisputeInformation] = useState({
    receiptData: {
      type: "hyperlink",
      name: "receiptData",
      userFriendlyText: "View",
      value: props.disputeDetails.receiptData,
      fn: () => viewReceiptData(),
    },
    status: {
      type: "button",
      value: props.disputeDetails.status,
      name: "status",
      fn: () => {
        showHistoryLog();
      },
      buttonText: "History Log",
    },
  });

  const getDisputeDetails = useCallback(async () => {
    setLoading(true);
    try {
      const url = BACK_OFFICE_API.DISPUTES.GET_DISPUTE;
      const response = await API_SERVICE.MAKE_GET_REQUEST(
        `${url}/${props.disputeDetails.transactionSearchKey}`
      );
      let dispute = response;
      let { statusLogInfo, receiptData, ...restDisputeProperties } = dispute;
      dispute = {
        ...restDisputeProperties,
        ...(receiptData
          ? { ...additionalDisputeInformation }
          : { receiptData }),
        status: {
          ...additionalDisputeInformation.status,
          value: response?.status,
        },
      };
      const { settlementInfo, ...disputeDetails } = dispute;
      setDisputeSettlementInformation(settlementInfo);
      setDisputeDetails(disputeDetails);
      setLoading(false);
    } catch (error) {
      setError(HELPER.PROCESS_ERROR(error));
      setLoading(false);
    }
  }, [props.disputeDetails.transactionSearchKey, additionalDisputeInformation]);

  useEffect(() => {
    getDisputeDetails();
  }, [getDisputeDetails]);

  const goBack = () => {
    setCurrentIndex(0);
  };

  const viewReceiptData = () => {
    setShowReceiptData(true);
  };
  const closeReceiptData = () => {
    setShowReceiptData(false);
  };

  const showHistoryLog = () => {
    setCurrentIndex(1);
  };

  const toggleModal = (modalIndex) => {
    setModalIndex(modalIndex);
    setIsModalVisible(!isModalVisible);
  };

  const renderCurrentView = () => {
    switch (currentIndex) {
      case 0:
        return disputeDetailsView();
      case 1:
        return renderDisputeHistoryLog();
      default:
        break;
    }
  };

  const renderDisputeHistoryLog = () => {
    return (
      <TransactionDisputeHistoryLog
        logs={props.statusLogInfo}
        goBack={goBack}
      />
    );
  };

  const modalContent = () => {
    switch (modalIndex) {
      case 0:
        return (
          <ResolveDispute
            closeModal={toggleModal}
            dispute={props.disputeDetails}
            onSuccess={getDisputeDetails}
          />
        );
      case 1:
        return (
          <ReopenDispute
            closeModal={toggleModal}
            dispute={props.disputeDetails}
            onSuccess={getDisputeDetails}
          />
        );
      default:
        break;
    }
  };

  const canPerformActionOnDispute = () => {
    const {
      status: disputeStatus,
      resolutionStatus,
      loggedBy,
      resolvedBy,
    } = disputeDetails;
    const canResolveDispute =
      disputeStatus?.value === PENDING && loggedBy !== loggedInUsersUsername;
    const canReopenDispute =
      resolutionStatus === DECLINED && resolvedBy !== loggedInUsersUsername;
    return canResolveDispute || canReopenDispute;
  };

  const canResolveDispute = (disputeStatus) => {
    return (
      disputeStatus?.value === PENDING &&
      disputeDetails?.loggedBy !== loggedInUsersUsername &&
      HELPER.HAS_AUTHORITY("switch_resolve_dispute")
    );
  };

  const canReopenDispute = (resolutionStatus) => {
    return (
      resolutionStatus === DECLINED &&
      HELPER.HAS_AUTHORITY("switch_reopen_dispute")
    );
  };

  const disputeDetailsView = () => {
    const { status: disputeStatus, resolutionStatus } = disputeDetails;
    return (
      <>
        <CustomModal
          closeModal={toggleModal}
          onHide={toggleModal}
          visible={isModalVisible}
          modalContent={modalContent}
        />
        <div className="p-text-left ml-3 p-mt-2 p-pb-3">
          <span onClick={props?.goBack} className="add-cursor">
            <span>
              <Icon icon="go-back-icon" />
            </span>
          </span>
        </div>
        <TabView>
          <TabPanel header="Dispute Details">
            <div className="p-text-left p-2">
              <div className="p-grid ">
                <div className="p-col-8" />
              </div>
              <Optional showIf={canPerformActionOnDispute()}>
                <div className="p-3 d-flex flex-column">
                  <div className="d-flex align-self-end pb-4">
                    <Optional showIf={canResolveDispute(disputeStatus)}>
                      <AppButton
                        text="Resolve"
                        type="button"
                        buttonStyle="primary"
                        onclick={() => toggleModal(0)}
                      />
                    </Optional>
                    <Optional showIf={canReopenDispute(resolutionStatus)}>
                      <div className="ml-2">
                        <AppButton
                          text="Reopen"
                          type="button"
                          buttonStyle="tertiary"
                          onclick={() => toggleModal(1)}
                        />
                      </div>
                    </Optional>
                  </div>
                </div>
              </Optional>
              <div className="centralize">
                <div className="p-2">
                  <FormattedDetails
                    details={disputeDetails}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          {disputeSettlementInformation && (
            <TabPanel header="Settlement Refund Information">
              <div className="p-text-left p-2">
                <div className="centralize">
                  <div className="p-2">
                    <FormattedDetails details={disputeSettlementInformation} />
                  </div>
                </div>
              </div>
            </TabPanel>
          )}
        </TabView>
      </>
    );
  };

  return (
    <div
      style={{ backgroundColor: "#ffffff", paddingTop: "1em" }}
      className="p-shadow-1 p-pb-5 mt-5"
    >
      {error ? (
        <ErrorView onReload={getDisputeDetails} error={error} />
      ) : (
        renderCurrentView()
      )}
      <Optional showIf={showReceiptData}>
        <ImageView
          close={closeReceiptData}
          image={props.disputeDetails.receiptData}
        />
      </Optional>
    </div>
  );
};

export default DisputeDetails;
