import { BACK_OFFICE_API } from "api/backofffice/index";
import { useFinancialInstitutionsList } from "api/hooks/use-financial-institutions-list";
import { API_SERVICE } from "api/service";
import { HELPER } from "core/helper/helper";
import { useCallback, useContext, useEffect, useState } from "react";
import { EMAIL_REGEX } from "shared/constants";
import * as Yup from "yup";
import { InputContext } from "core/providers/InputProvider";

const useAcquirerForm = (props, partyType) => {
  const { country } = useContext(InputContext);
  const { financialInstitutions } = useFinancialInstitutionsList();
  const [partySourceInfo, setPartySourceInfo] = useState({});
  const [initialValues, setInitialValues] = useState({
    financialInstitutionCode: "",
    adminEmail: "",
    supportEmail: "",
    settlementEmail: "",
    disputeEmail: "",
    phone: "",
    status: "",
  });
  const [validationSchema, setValidationSchema] = useState({
    financialInstitutionCode: Yup.string().required(
      "Financial institution is required"
    ),
  });
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [toast, setToast] = useState({
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(false);
  const [acquirerInfo, setAcquirerInfo] = useState({});
  const pageIndex = {
    PRIMARY_INFO_INDEX: 0,
    CONTACT_INFO_INDEX: 1,
    CONNECTION_INFO_INDEX: 2,
  };
  const [stepWizardModel] = useState([
    { label: "Primary Info" },
    { label: "Contact Info" },
    { label: "Connection Info" },
  ]);

  const getPartySourceInfo = useCallback(
    async (partyCode) => {
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          `${BACK_OFFICE_API.PARTY_SOURCE_INFO.PARTY_SOURCE_INFO}/${partyType}/${partyCode}`
        );
        const partySource = response?.result;
        handlePartySourceResponse(partySource);
      } catch (error) {
        setToast({
          message: HELPER.PROCESS_ERROR(error),
          type: "error",
        });
      }
    },
    [partyType]
  );

  useEffect(() => {
    if (props.isUpdate) {
      const {
        financialInstitutionCode,
        adminEmail,
        supportEmail,
        settlementEmail,
        disputeEmail,
        phone,
        status,
      } = HELPER.changeNullValuesToEmptyStrings(props.acquirer);
      setInitialValues({
        financialInstitutionCode,
        adminEmail,
        supportEmail,
        settlementEmail,
        disputeEmail,
        phone,
        status,
      });
    }
  }, [props.isUpdate, props.acquirer]);

  const handlePartySourceResponse = (partySource) => {
    const {
      partyTypeId,
      partyType,
      createdBy,
      createdAt,
      updatedAt,
      active,
      ...result
    } = partySource;
    setPartySourceInfo(
      HELPER.changeNullValuesToEmptyStrings({ ...result, status: active })
    );
  };

  const handleResponse = (response) => {
    setToast({
      message: "",
      type: "",
    });
    setAcquirerInfo(response?.result);
    if (props.isUpdate) {
      getPartySourceInfo(props.acquirer.code);
    }
    setCurrentFormIndex(pageIndex.CONNECTION_INFO_INDEX);
    setLoading(false);
  };

  const handleError = (error) => {
    setToast({
      message: HELPER.PROCESS_ERROR(error),
      type: "error",
    });
    setLoading(false);
    setCurrentFormIndex(pageIndex.CONTACT_INFO_INDEX);
  };

  const handleClose = () => {
    if (currentFormIndex === pageIndex.PRIMARY_INFO_INDEX) {
      props.closeModal();
    } else if (currentFormIndex === pageIndex.CONTACT_INFO_INDEX) {
      setValidationSchema({
        financialInstitutionCode: Yup.string().required(
          "Financial institution is required"
        ),
      });
      setCurrentFormIndex(pageIndex.PRIMARY_INFO_INDEX);
    }
  };

  const updateAcquirer = async (payload) => {
    const url = BACK_OFFICE_API.ACQUIRER.ACQUIRER;
    try {
      const response = await API_SERVICE.MAKE_PUT_REQUEST(
        `${url}/${props?.acquirer?.code}`,
        payload
      );
      handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  };

  const createAcquirer = async (payload) => {
    const url = BACK_OFFICE_API.ACQUIRER.ACQUIRER;
    try {
      const response = await API_SERVICE.MAKE_POST_REQUEST(url, payload);
      handleResponse(response);
    } catch (error) {
      handleError(error);
    }
  };
  const updatePhoneValidation = useCallback(() => {
    setValidationSchema((prev) => ({
      ...prev,
      phone: Yup.string().test(
        "test-name",
        "Must be a valid phone number",
        function (value) {
          return HELPER.validatePhoneNumber(value, country);
        }
      ),
    }));
  }, [country]);

  useEffect(() => {
    if (currentFormIndex === pageIndex.CONTACT_INFO_INDEX) {
      updatePhoneValidation();
    }
  }, [
    country,
    currentFormIndex,
    updatePhoneValidation,
    pageIndex.CONTACT_INFO_INDEX,
  ]);

  const submit = async ({ status, phone, ...payload }) => {
    setToast({
      message: "",
      type: "",
    });
    if (currentFormIndex === pageIndex.PRIMARY_INFO_INDEX) {
      setValidationSchema({
        ...validationSchema,
        adminEmail: Yup.string()
          .matches(EMAIL_REGEX, "Enter a valid email")
          .max(50, "Admin mail cannot exceed 50 characters!"),
        supportEmail: Yup.string()
          .matches(EMAIL_REGEX, "Enter a valid email")
          .max(50, "Support email cannot exceed 50 characters!"),
        settlementEmail: Yup.string()
          .matches(EMAIL_REGEX, "Enter a valid email")
          .max(50, "Settlement email cannot exceed 50 characters!")
          .required("Settlement email is required"),
        disputeEmail: Yup.string()
          .matches(EMAIL_REGEX, "Enter a valid email")
          .max(50, "Dispute email cannot exceed 50 characters!"),
        phone: Yup.string().test(
          "test-name",
          "Must be a valid phone number",
          function (value) {
            return HELPER.validatePhoneNumber(value, country);
          }
        ),
      });
      setCurrentFormIndex(pageIndex.CONTACT_INFO_INDEX);
    } else if (currentFormIndex === pageIndex.CONTACT_INFO_INDEX) {
      const countryCode = HELPER.getCountryCode(country);
      setLoading(true);
      // the end point does not accept phone as an empty string
      const formattedPayload = {
        ...payload,
        phone: phone === "" ? null : `${countryCode}${phone}`,
        active: status,
      };
      if (props.isUpdate) {
        updateAcquirer(formattedPayload);
      } else {
        createAcquirer(formattedPayload);
      }
    }
  };

  const goBack = () => {
    setCurrentFormIndex(1);
  };

  const closeBackButtonText =
    currentFormIndex === pageIndex.PRIMARY_INFO_INDEX ? "Close" : "Back";

  const submitNextButtonText =
    currentFormIndex <= pageIndex.CONNECTION_INFO_INDEX ? "Next" : "Submit";

  const actions = {
    submit,
    handleClose,
    setInitialValues,
    goBack,
  };
  const api = {
    loading,
    toast,
    acquirerInfo,
    partySourceInfo,
    financialInstitutions,
  };

  const formInfo = {
    ...pageIndex,
    initialValues,
    closeBackButtonText,
    submitNextButtonText,
    validationSchema,
    stepWizardModel,
    currentFormIndex,
  };

  return { api, actions, formInfo };
};

export default useAcquirerForm;
