const serviceUrl = document.getElementById("service-url")?.innerHTML.trim();
const aptpayHost = serviceUrl?.length
  ? serviceUrl
  : "https://switch.development.teamapt.com:443";

const API_BASE_URL = aptpayHost || process.env.REACT_APP_API_BASE_URL;

const defaultMaxDaysAgoForTransactionFilter = 10;

const aptentUiStartDayFromNow = document
  .getElementById("aptent-ui-start-day-from-now")
  ?.innerHTML.trim();

const maxDaysAgoForTransactionFilter = aptentUiStartDayFromNow?.length
  ? aptentUiStartDayFromNow
  : defaultMaxDaysAgoForTransactionFilter;

const MAX_DAYS_AGO_FOR_TRANSACTION_FILTER =
  maxDaysAgoForTransactionFilter ||
  process.env.REACT_APP_MAX_DAYS_AGO_FOR_TRANSACTION_FILTER;

export const DEFAULT_PAGE_SIZE = process.env.REACT_APP_DEFAULT_PAGE_SIZE || 10;

const getEncryptedAuthorities = document
  .getElementById("isEncryptedAuthorities")
  .innerHTML?.trim();

const isEncryptedAuthorities =
  !!getEncryptedAuthorities && getEncryptedAuthorities === "true";

export const CONFIG = {
  API_BASE_URL,
  API_VERSION: process.env.REACT_APP_API_VERSION || "v1",
  APP_IDLE_TIMEOUT_IN_MINUTES:
    process.env.REACT_APP_IDLE_TIMEOUT_IN_MINUTES || 5,
  MAX_DAYS_AGO_FOR_TRANSACTION_FILTER,
  DEFAULT_PAGE_SIZE,
  IS_ENCRYPTED_AUTHORITIES: isEncryptedAuthorities,
};
