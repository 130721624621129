import { api, getAuthHeaders } from "./client";

function getBlobObject() {
  return {
    responseType: "blob",
  };
}

function MAKE_GET_REQUEST(url, isBlob = false) {
  const headers = getAuthHeaders();
  const blobConfig = getBlobObject();
  return new Promise((resolve, reject) => {
    api
      .get(url, { headers, ...(isBlob && blobConfig) })
      .then(function (data) {
        // console.log("source-node",  data);
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function MAKE_PUT_REQUEST(url, payload, isBlob = false) {
  const headers = getAuthHeaders();
  const blobConfig = getBlobObject();
  return new Promise((resolve, reject) => {
    api
      .put(url, payload, { headers, ...(isBlob && blobConfig) })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function MAKE_POST_REQUEST(url, payload, isBlob = false) {
  const headers = getAuthHeaders();
  const blobConfig = getBlobObject();
  return new Promise((resolve, reject) => {
    api
      .post(url, payload, { headers, ...(isBlob && blobConfig) })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

function MAKE_DELETE_REQUEST(url) {
  const header = getAuthHeaders();
  return new Promise((resolve, reject) => {
    api
      .delete(url, { headers: header })
      .then(function (data) {
        resolve(data.data);
      })
      .catch(function (error) {
        if (error.response) {
          reject(error.response);
        }
      });
  });
}

export const API_SERVICE = {
  MAKE_GET_REQUEST,
  MAKE_POST_REQUEST,
  MAKE_PUT_REQUEST,
  MAKE_DELETE_REQUEST,
};
