import axios from "axios";
import { CONFIG } from "../core/configs/configs";
import { storage } from "../core/utils/storage";

export const api = axios.create({
  baseURL: CONFIG.API_BASE_URL,
});

function authRequestInterceptor(config) {
  const token = storage.getToken();
  if (token) {
    config.headers.authorization = `${token}`;
  }
  config.headers.Accept = "application/json";
  return config;
}

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const status = error.response.status;
    if (status === 401 || status === 406) {
      if (window.location.pathname !== "/login") {
        localStorage.clear();
        window.location.href = "/login?session_expired=true";
      }
    }
    return Promise.reject(error);
  }
);

api.interceptors.request.use(authRequestInterceptor);

export const getAuthHeaders = () => {
  const token = storage.getToken();
  return {
    Authorization: `${token}`,
  };
};
