import React, { useCallback, useEffect, useState } from "react";
import { CustomLoader } from "shared/components/custom-loader/custom-loader";
import { CustomModal } from "shared/components/custom-modal/custom-modal";
import { CustomTable } from "../../../shared/components/custom-table/custom-table";
import { DEFAULT_PAGE_SIZE } from "../../../core/configs/configs";
import { HELPER } from "../../../core/helper/helper";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { CustomBreadcrumb } from "../../../shared/components/custom-breadcrumb/custom-breadcrumb";
import { PageActions } from "../../../shared/components/page-actions/page-actions";
import { PageTitle } from "../../../shared/components/page-title/page-title";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { Optional } from "../../../shared/components/optional/optional";
import { useNotification } from "core/hooks/useNotification";
import { API_SERVICE } from "api/service";
import { NssSettlementSessionsFilter } from "./nss-settlement-sessions-filter";
import NssSettlementSessionsDetails from "./nss-settlement-sessions-details";
import { LOCAL_STORAGE_SERVICE } from "core/services/storage-service";
import { ConfirmationDialog } from "shared/components/confirmation-dialog/confirmation-dialog";
import { saveAs } from "file-saver";

export function NssSettlementSessions() {
  const partyType = LOCAL_STORAGE_SERVICE.GET_PARTY_TYPE();
  const partyUserId = LOCAL_STORAGE_SERVICE.GET_PARTY_USER_ID();
  const isFinancialInstitution = partyType === "FINANCIAL_INSTITUTION";
  const [pageIndex, setPageIndex] = useState(0);
  const { addNotification } = useNotification();
  const [modalIndex, setModalIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [settlementSessions, setSettlementSessions] = useState([]);
  const [selectedNssSettlementSession, setSelectedNssSettlementSession] =
    useState(null);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [error, setError] = useState(null);
  const [filterParams, setFilterParams] = useState(null);
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);
  const tableHeaders = [
    { label: "Code", value: "code" },
    {
      label: "Settlement Date",
      value: "settlementDate",
    },
    { label: "Status", value: "status" },
    { label: "Created Date", value: "createdAt" },
    isFinancialInstitution && {
      label: "File Name",
      value: "fileName",
    },
    { label: "Actions", value: "actions" },
  ];
  const tableAuthorities = [{ label: "OPEN", value: "all" }];
  const tableActions = {
    ...(isFinancialInstitution &&
      HELPER.HAS_AUTHORITY("switch_download_nss_file_summary") && {
        DOWNLOAD: "DOWNLOAD",
      }),
    OPEN: "OPEN",
  };
  const FILTER_MODAL_INDEX = 0;
  const DOWNLOAD_MODAL_INDEX = 1;
  const { TABLE_INDEX, PAGE_DETAILS_INDEX } = {
    TABLE_INDEX: 0,
    PAGE_DETAILS_INDEX: 1,
  };

  const handleResponse = useCallback((response) => {
    setPagination(response);
    setSettlementSessions(response?.content);
    setError(null);
    setLoading(false);
  }, []);

  const handleError = useCallback(
    (error) => {
      setLoading(false);
      setError(HELPER.PROCESS_ERROR(error));
      addNotification({
        message: HELPER.PROCESS_ERROR(error),
        type: "error",
      });
    },
    [addNotification]
  );

  const downloadSettlementFile = async () => {
    setIsDownloadingFile(true);
    const { NSS_SETTLEMENT_SESSION_DOWNLOAD } =
      BACK_OFFICE_API.NSS_SETTLEMENT_SESSIONS;
    const isBlob = true;
    const fileName = selectedNssSettlementSession.fileName;
    try {
      const response = await API_SERVICE.MAKE_GET_REQUEST(
        `${NSS_SETTLEMENT_SESSION_DOWNLOAD}/${fileName}`,
        isBlob
      );
      saveAs(response, `NSS-Settlement-Session-${fileName}`);
      const message = `Successfully downloaded ${fileName}!`;
      closeModal();
      addNotification({
        message,
        type: "success",
      });
      setIsDownloadingFile(false);
    } catch (error) {
      addNotification({
        message: HELPER.PARSE_BLOB_ERROR(error),
        type: "error",
      });
      setIsDownloadingFile(false);
    }
  };

  const getSettlementSessions = useCallback(
    async (filters) => {
      setLoading(true);
      const params = HELPER.TO_URL_STRING({
        page: pageNo,
        size: DEFAULT_PAGE_SIZE,
        ...filters,
      });
      const {
        NSS_SETTLEMENT_SESSIONS_BASE_URL,
        SETTLEMENT_SESSION_BY_FINANCIAL_INSTITUTION,
      } = BACK_OFFICE_API.NSS_SETTLEMENT_SESSIONS;
      const financialInstitutionsRoute = `${SETTLEMENT_SESSION_BY_FINANCIAL_INSTITUTION}/${partyUserId}/sessions`;
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          `${
            isFinancialInstitution
              ? financialInstitutionsRoute
              : NSS_SETTLEMENT_SESSIONS_BASE_URL
          }?${params}`
        );
        handleResponse(response);
      } catch (error) {
        handleError(error);
      }
    },
    [pageNo, handleResponse, handleError]
  );

  useEffect(() => {
    if (!filterParams) {
      getSettlementSessions();
    } else {
      getSettlementSessions(filterParams);
    }
  }, [getSettlementSessions, filterParams]);

  function reload() {
    setPageNo(0);
    setFilterParams(null);
  }

  const goBack = (index) => {
    setPageIndex(index);
  };

  function toggleModal(index) {
    setModalIndex(index);
    setIsModalVisible(!isModalVisible);
  }

  function closeModal(shouldReload) {
    if (!modalIndex) {
      setPageNo(TABLE_INDEX);
    }
    setIsModalVisible(false);
    if (shouldReload === true) {
      getSettlementSessions();
    }
  }

  const handleTableAction = (data, action) => {
    switch (action) {
      case "OPEN": {
        setSelectedNssSettlementSession(data);
        setPageIndex(1);
        return;
      }
      case "DOWNLOAD": {
        setSelectedNssSettlementSession(data);
        toggleModal(DOWNLOAD_MODAL_INDEX);
        return;
      }
    }
  };

  const handleFilterClick = () => {
    toggleModal(0);
  };

  const handleFilter = (filter) => {
    setFilterParams(filter);
  };

  function modalContent() {
    if (modalIndex === FILTER_MODAL_INDEX) {
      return (
        <NssSettlementSessionsFilter
          closeModal={closeModal}
          onFilter={handleFilter}
        />
      );
    } else if (modalIndex === DOWNLOAD_MODAL_INDEX) {
      return (
        <ConfirmationDialog
          headerText="Download Settlement File"
          confirmationText="Please confirm you want to download this file."
          onSubmit={downloadSettlementFile}
          onClose={closeModal}
          loading={isDownloadingFile}
        />
      );
    }
  }

  const renderTable = () => {
    if (loading) {
      return (
        <div className="loading-container text-center">
          <CustomLoader loadingText="Loading..." />
        </div>
      );
    }

    return (
      <CustomTable
        authorities={tableAuthorities}
        isReload={true}
        numberOfElements={pagination?.numberOfElements}
        totalPages={pagination?.totalPages}
        totalItems={pagination?.totalElements}
        currentPage={pageNo + 1}
        emptyText={"No NSS settlement sessions found!"}
        search={true}
        reload={reload}
        error={error}
        items={settlementSessions}
        loading={loading}
        headers={tableHeaders}
        nextPage={() => setPageNo(pageNo + 1)}
        prevPage={() => setPageNo(pageNo - 1)}
        goToFirstPage={() => setPageNo(0)}
        goToLastPage={() => setPageNo(pagination?.totalPages - 1)}
        goToPage={(pageNo) => setPageNo(pageNo)}
        actions={Object.values(tableActions)}
        onPerformAction={handleTableAction}
      />
    );
  };

  const currentPageView = () => {
    switch (pageIndex) {
      case TABLE_INDEX:
        return renderTable();
      case PAGE_DETAILS_INDEX:
        return (
          <div className="mt-5">
            <NssSettlementSessionsDetails
              goBack={goBack}
              details={selectedNssSettlementSession}
            />
          </div>
        );
      default:
        break;
    }
  };

  return (
    <div className="manage-nss-configs">
      <>
        <CustomModal
          closeModal={closeModal}
          onHide={closeModal}
          visible={isModalVisible}
          modalContent={modalContent}
          hasOwnModalHeader={true}
        />
      </>
      <PageTitle text="NSS Settlement Sessions" />
      <CustomBreadcrumb page="Manage Settlement Sessions" />
      <Optional showIf={HELPER.HAS_AUTHORITY("all") && pageIndex === 0}>
        <PageActions>
          <AppButton
            icon="filter"
            text="Filter"
            type="button"
            buttonStyle="primary"
            margin={"mr-2"}
            onclick={handleFilterClick}
          />
        </PageActions>
      </Optional>
      <>{currentPageView()}</>
    </div>
  );
}
