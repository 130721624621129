import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { CustomToast } from "../../../shared/components/alert/custom-toast";
import { HELPER } from "../../../core/helper/helper";
import { BACK_OFFICE_API } from "../../../api/backofffice/index";
import { AppFormDropdown } from "../../../shared/components/form-inputs/form-dropdown";
import { Optional } from "../../../shared/components/optional/optional";
import { useNotification } from "core/hooks/useNotification";
import { FormattedDetails } from "shared/components/formatted-details/formatted-details";
import { API_SERVICE } from "api/service";
import { PARTY_TYPES } from "shared/constants";
import { PartyTypePartyIdentifierFormInputs } from "./party-type-party-identifier-form-inputs";

export function PartyUserForm(props) {
  const { addNotification } = useNotification();
  const FORM_VIEW_INDEX = 0;
  const FORM_DETAILS_CONFIRMATION_INDEX = 1;
  const [toastType, setToastType] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    email: "",
    firstname: "",
    lastname: "",
    partyIdentifier: "",
    partyType: "",
    role: "",
  });
  const validationSchemaObject = {
    email: Yup.string().email("Enter a valid email").required("Required!"),
    firstname: Yup.string().required("Required"),
    lastname: Yup.string().required("Required"),
    partyIdentifier: Yup.string().required("Required"),
    partyType: Yup.string().required("Required"),
    role: Yup.string().required("Required"),
  };
  const [roles, setRoles] = useState([]);
  const [currentFormIndex, setCurrentFormIndex] = useState(0);
  const [formValues, setFormValues] = useState(null);
  const formattedPartyTypes = PARTY_TYPES.filter((partyType) => {
    const value = partyType.value;
    return (
      value !== "ACQUIRER" && value !== "ISSUER_PROCESSOR" && value !== "ISSUER"
    );
  });

  useEffect(() => {
    if (props.isUpdate) {
      const { email, firstname, lastname, partyIdentifier, partyType, role } =
        props.partyUser;
      setInitialValues({
        email,
        firstname,
        lastname,
        partyIdentifier,
        partyType,
        role,
      });
    }
  }, [props.isUpdate, props.partyUser]);

  const handleResponse = (isCreate = false) => {
    let notification = {
      type: "success",
      message: `User successfully ${isCreate ? "created" : "updated"}!`,
    };
    addNotification(notification);
    props.closeModal();
    props.onSuccess();
    setLoading(false);
  };

  const handleError = (error) => {
    setToastType("error");
    setMessage(HELPER.PROCESS_ERROR(error));
    setLoading(false);
  };

  const resetToast = () => {
    setToastType("");
    setMessage("");
  };

  const createPartyUser = async (payload) => {
    resetToast();
    const url = BACK_OFFICE_API.PARTY_USERS.CREATE_PARTY_USER;
    try {
      await API_SERVICE.MAKE_POST_REQUEST(url, payload);
      const isCreate = true;
      handleResponse(isCreate);
    } catch (error) {
      handleError(error);
    }
  };

  const updatePartyUser = async (payload) => {
    resetToast();
    const url = BACK_OFFICE_API.PARTY_USERS.CREATE_PARTY_USER;
    try {
      await API_SERVICE.MAKE_PUT_REQUEST(
        `${url}/${props?.partyUser?.code}`,
        payload
      );
      handleResponse();
    } catch (error) {
      handleError(error);
    }
  };

  const handleClose = () => {
    if (currentFormIndex === FORM_VIEW_INDEX) {
      props.closeModal();
    } else {
      setCurrentFormIndex(FORM_VIEW_INDEX);
    }
  };

  const submit = async (payload) => {
    if (currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX) {
      setLoading(true);
      if (props.isUpdate) {
        updatePartyUser(payload);
      } else {
        createPartyUser(payload);
      }
    } else {
      setFormValues(payload);
      setCurrentFormIndex(FORM_DETAILS_CONFIRMATION_INDEX);
    }
  };

  useEffect(() => {
    const getRoles = async () => {
      try {
        const response = await API_SERVICE.MAKE_GET_REQUEST(
          BACK_OFFICE_API.PARTY_USERS.GET_ROLES
        );
        setRoles(response?.result);
      } catch (error) {
        addNotification({
          type: "error",
          message: HELPER.PROCESS_ERROR(error),
        });
      }
    };
    getRoles();
  }, [addNotification]);

  const renderForm = () => {
    return (
      <div>
        <p className="modal-title p-text-left mb-1">Create User</p>
        <p className="modal-subtitle mt-0 mb-1">Fields marked * are required</p>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object(validationSchemaObject)}
          onSubmit={(values, { resetForm }) => {
            submit(values, resetForm);
          }}
          enableReinitialize={true}
        >
          {({ values, errors, setFieldValue }) => (
            <Form>
              <Optional
                showIf={currentFormIndex === FORM_DETAILS_CONFIRMATION_INDEX}
              >
                <FormattedDetails details={formValues} />
              </Optional>
              <Optional showIf={currentFormIndex === FORM_VIEW_INDEX}>
                <AppFormInput
                  label="Firstname"
                  name="firstname"
                  type="text"
                  placeholder="Firstname"
                  required={true}
                />
                <AppFormInput
                  label="Lastname"
                  name="lastname"
                  type="text"
                  placeholder="Lastname"
                  required={true}
                />
                <AppFormInput
                  required={true}
                  label="Email"
                  name="email"
                  type="text"
                  placeholder="Email"
                  disabled={false}
                />
                <AppFormDropdown
                  value={values.role}
                  onchange={(e) => {
                    setFieldValue("role", e?.target?.value);
                  }}
                  options={roles}
                  placeholder="Select role"
                  label="Role"
                  error={errors?.role}
                  field="role"
                  required
                  filter
                />
                <PartyTypePartyIdentifierFormInputs
                  values={values}
                  errors={errors}
                  setFieldValue={setFieldValue}
                  partyTypes={formattedPartyTypes}
                  identifierField="partyIdentifier"
                  partyTypeField="partyType"
                  partyTypePlaceholder="Select party type"
                  identiferPlaceholder="Select identifier"
                  partyTypeLabel="Party Type"
                  identifierLabel="Party Identifier"
                  isRequiredField={true}
                />
              </Optional>

              {message && (
                <CustomToast
                  title={toastType === "error" ? "Error" : "Success"}
                  description={message}
                  type={toastType}
                  closeable={false}
                  inApp={true}
                />
              )}
              <FormActions>
                <AppButton
                  type="button"
                  buttonStyle="secondary"
                  text={`${
                    currentFormIndex === FORM_VIEW_INDEX ? "Close" : "Back"
                  }`}
                  onclick={handleClose}
                />
                <AppButton
                  type="submit"
                  buttonStyle="primary"
                  text={`${
                    currentFormIndex === FORM_VIEW_INDEX ? "Next" : "Submit"
                  }`}
                  loading={loading}
                />
              </FormActions>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  return <>{renderForm()}</>;
}
