import { BACK_OFFICE_API } from "api/backofffice/index";
import { AppButton } from "shared/components/app-button/app-button";
import { saveAs } from "file-saver";
import { HELPER } from "core/helper/helper";
import { CustomToast } from "shared/components/alert/custom-toast";
import { useCallback, useEffect, useRef, useState } from "react";
import { COMPLETED, FAILED, IN_PROGRESS, PENDING } from "shared/constants";
import { API_SERVICE } from "api/service";
import { useNotification } from "core/hooks/useNotification";
import { Skeleton } from "primereact/skeleton";

export function SessionGenerationProgress({ session, closeModal }) {
  const [message, setMessage] = useState("Your request is being processed.");
  const [isReportAvailable, setIsReportAvailable] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const reportStatusTimeout = useRef(null);
  const [downloadingReport, setDownloadingReport] = useState(false);
  const { addNotification } = useNotification();
  const [downloadUrl, setDownloadUrl] = useState("");
  const handleReportStatusResponse = useRef(null);
  const getReportStatus = useRef(null);
  const waitTimeBeforeReloadInSeconds = 5;

  const downloadReport = async () => {
    setErrorMessage(null);
    setDownloadingReport(true);
    const url = downloadUrl;
    const isBlob = true;

    try {
      const response = await API_SERVICE.MAKE_GET_REQUEST(url, isBlob);
      addNotification({
        type: "success",
        message: "File download in progress!",
      });
      saveAs(response, "NSS-Session-Breakdown.xlsx");
      setDownloadingReport(false);
      closeModal();
    } catch (error) {
      const errMessage = await HELPER.PARSE_BLOB_ERROR(error);
      setErrorMessage(errMessage);
      setDownloadingReport(false);
    }
  };

  handleReportStatusResponse.current = useCallback((response) => {
    const { status, errorMessage, message, downloadUrl } = response;
    if (status === FAILED) {
      setErrorMessage(errorMessage || message);
      return;
    }
    if (status === COMPLETED) {
      setMessage(message);
      clearTimeout(reportStatusTimeout.current);
      setDownloadUrl(downloadUrl);
      setIsReportAvailable(true);
      return;
    }
    if (status === IN_PROGRESS || status === PENDING) {
      setMessage(message || "Your request is being processed.");
      reportStatusTimeout.current = setTimeout(() => {
        getReportStatus.current();
      }, waitTimeBeforeReloadInSeconds * 1000);
    }
  }, []);

  getReportStatus.current = useCallback(async () => {
    setErrorMessage(null);
    try {
      const response = await API_SERVICE.MAKE_GET_REQUEST(
        `${BACK_OFFICE_API.NSS_SETTLEMENT_SESSIONS.NSS_SETTLEMENT_SESSION_TRANSACTIONS_DOWNLOAD_STATUS}/${session}/transaction/report-status`
      );
      handleReportStatusResponse.current(response);
    } catch (error) {
      clearTimeout(reportStatusTimeout.current);
      setErrorMessage(HELPER.PROCESS_ERROR(error));
    }
  }, [session]);

  useEffect(() => {
    getReportStatus.current();
  }, [getReportStatus]);

  return (
    <div style={{ fontSize: "0.9rem" }}>
      <p className="confirm-text">Session Breakdown Generation</p>

      <div className="px-1">
        <p>{message}</p>
        {!isReportAvailable && <Skeleton borderRadius="16px" />}
      </div>

      {errorMessage && (
        <CustomToast title="Error" type="error" description={errorMessage} />
      )}
      <div className="mt-5 mb-2 grid">
        <div className={`${isReportAvailable ? "col-6" : "col-12"}`}>
          <button
            onClick={() => {
              closeModal();
              clearTimeout(reportStatusTimeout.current);
            }}
            className="secondary-button"
          >
            Dismiss
          </button>
        </div>
        {isReportAvailable && (
          <div className="col-6">
            <AppButton
              type="button"
              buttonStyle="primary"
              text="Download Breakdown"
              onclick={downloadReport}
              loading={downloadingReport}
              width="w-full"
            />
          </div>
        )}
      </div>
    </div>
  );
}
