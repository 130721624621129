import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./App.css";
import "../node_modules/primeflex/primeflex.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { LoginPage } from "./shared/pages/login";
import { MainProvider } from "./core/providers/MainProvider";
import { AppLayout } from "./layout/app-layout";
import { AppAlert } from "./shared/components/app-alert/app-alert";
import { NotificationProvider } from "./core/providers/NotificationProvider";
import { backofficeRoutes } from "./backoffice/routes/index";
import * as yup from "yup";
import { CUSTOM_VALIDATION } from "./core/utils/validation";
import { ChangePassword } from "./shared/pages/change-password/change-password";
import { AppNotification } from "./shared/components/app-notification/app-notification";
import { InputProvider } from "core/providers/InputProvider";
// import axios from "axios";

yup.addMethod(yup.string, "isValidCode", CUSTOM_VALIDATION.IS_VALID_CODE);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/login" replace />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/change-password",
    element: <ChangePassword />,
  },
  {
    path: "/",
    element: <AppLayout />,
    children: backofficeRoutes,
  },
]);

export const MainContext = React.createContext();

ReactDOM.createRoot(document.getElementById("root")).render(
  <MainProvider>
    <NotificationProvider>
      <InputProvider>
        <AppAlert />
        <AppNotification />
        <RouterProvider router={router} />
      </InputProvider>
    </NotificationProvider>
  </MainProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
