import { Formik, Form } from "formik";
import { AppButton } from "../../../shared/components/app-button/app-button";
import { AppFormInput } from "../../../shared/components/form-inputs/form-input";
import { FormActions } from "../../../shared/components/utilities/form-actions";
import * as Yup from "yup";
import { useState } from "react";
import { PARTY_TYPES } from "shared/constants";
import { HELPER } from "core/helper/helper";
import { PartyTypePartyIdentifierFormInputs } from "./party-type-party-identifier-form-inputs";

export function PartyUsersFilter(props) {
  const [initialValues] = useState({
    partyIdentifier: "",
    partyType: "",
    searchParam: "",
  });
  const validationSchemaObject = {
    partyIdentifier: Yup.string(),
    partyType: Yup.string(),
    searchParam: Yup.string(),
  };

  const submit = (payload) => {
    const trimmedPayload = HELPER.TRIM_OBJECT(payload);
    props.onFilter(trimmedPayload);
  };

  return (
    <div>
      <p className="modal-title p-text-left mb-1">Filter Users</p>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object(validationSchemaObject)}
        onSubmit={(values) => {
          submit(values);
        }}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue }) => (
          <Form>
            <PartyTypePartyIdentifierFormInputs
              values={values}
              errors={errors}
              setFieldValue={setFieldValue}
              partyTypes={PARTY_TYPES}
              identifierField="partyIdentifier"
              partyTypeField="partyType"
              partyTypePlaceholder="Select party type"
              identiferPlaceholder="Select identifier"
              partyTypeLabel="Party Type"
              identifierLabel="Party Identifier"
              isRequiredField={false}
            />
            <AppFormInput
              label="Search Param"
              name="searchParam"
              type="text"
              placeholder="Search Param"
            />
            <FormActions>
              <AppButton
                type="button"
                buttonStyle="secondary"
                text="Close"
                onclick={props.closeModal}
              />
              <AppButton type="submit" buttonStyle="primary" text="Submit" />
            </FormActions>
          </Form>
        )}
      </Formik>
    </div>
  );
}
