import en from "react-phone-number-input/locale/en";
import { getCountries, getCountryCallingCode } from "react-phone-number-input";
import { default as flags } from "react-phone-number-input/flags";
import { useContext, useState } from "react";
import { Icon } from "../icons/icon";
import { InputContext } from "core/providers/InputProvider";

export const CountrySelect = () => {
  const { country, setCountry } = useContext(InputContext);
  const countries = getCountries();
  const [selectedCountry, setSelectedCountry] = useState(country);
  const labels = en;

  return (
    <div className="relative pr-2">
      <div className="flex align-items-center">
        <div className="flag">
          {flags[selectedCountry]({
            title: selectedCountry,
            heigh: "50",
            width: "50",
          })}
        </div>
        <span className="rotate-90">
          <Icon icon="dropdown" />
        </span>
      </div>
      <select
        style={{ top: "5px" }}
        className="absolute w-10 z-2 opacity-0 h-3rem"
        onChange={(e) => {
          setSelectedCountry(e.target.value);
          setCountry(e.target.value);
        }}
      >
        <option value={country}>
          {labels[country]} +{getCountryCallingCode(country)}
        </option>
        {countries.map((country) => {
          return (
            <option key={country} value={country}>
              {labels[country]} +{getCountryCallingCode(country)}
            </option>
          );
        })}
      </select>
    </div>
  );
};
