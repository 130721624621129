import { createContext, useState } from "react";

export const InputContext = createContext({
  country: "",
  setCountry: () => {},
  resetCountry: () => {},
});

export function InputProvider({ children }) {
  const [country, setCountry] = useState("NG");

  const contextValue = {
    country,
    resetCountry: () => {
      setCountry("NG");
    },
    setCountry: (payload) => {
      setCountry(payload);
    },
  };
  return (
    <InputContext.Provider value={contextValue}>
      {children}
    </InputContext.Provider>
  );
}
